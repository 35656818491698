import { Button, Typography, withStyles } from '@material-ui/core';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { VIEW_MODE, BaseUpsertComponent, useBaseUpsertComponent } from '@wings/shared';
import { useStyles } from './UserProfileRoleField.styles';
import { observer, inject } from 'mobx-react';
import { Dialog } from '@uvgo-shared/dialog';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import {
  UserProfileRolesModel,
  ServicesStore,
  ServicesModel,
  RolesModel,
  CustomerModel,
  CustomersStore,
  SiteModel,
  UserModel,
} from '../../../Shared';
import { fields } from './Fields';
import classNames from 'classnames';
import { IAPIGridRequest, IClasses, IOptionValue, ISelectOption, UIStore, Utilities } from '@wings-shared/core';
import {
  EDITOR_TYPES,
  ViewInputControl,
  IGroupInputControls,
  IViewInputControl,
  AutoCompleteControl,
} from '@wings-shared/form-controls';
import { action, observable } from 'mobx';
import { finalize, takeUntil, debounceTime } from 'rxjs/operators';
import { EnvironmentVarsStore, ENVIRONMENT_VARS } from '@wings-shared/env-store';
import { useParams } from 'react-router-dom';
import { useUnsubscribe } from '@wings-shared/hooks';

type Props = {
  classes: IClasses;
  title: string;
  roleField?: UserProfileRolesModel;
  viewMode?: VIEW_MODE;
  rolesField?: UserProfileRolesModel[];
  upsertRoleField: (roleField: UserProfileRolesModel) => void;
  serviceStore?: ServicesStore;
  customerStore?: CustomersStore;                 
  user?: UserModel;
};

const UserProfileRoleField: FC<Props> = ({ ...props }: Props) => {
  const [ serviceUsers, setServiceUsers ] = useState<ServicesModel[]>([]);
  const [ customer, setCustomer ] = useState<CustomerModel[]>([]);
  const [ selectedServices, setSelectedServices ] = useState<ServicesModel | null>(new ServicesModel());
  const [ selectedCustomer, setSelectedCustomer ] = useState<CustomerModel | null>(new CustomerModel());
  const [ selectedRole, setSelectedRole ] = useState<RolesModel | null>(new RolesModel());
  const [ selectedSite, setSelectedSite ] = useState<SiteModel | null>(new SiteModel());
  const localStates = observable({ showInlineError: false });
  const params = useParams();
  const useUpsert = useBaseUpsertComponent(params, fields);
  const classes = useStyles();
  const unsubscribe = useUnsubscribe();

  useEffect(() => {
    const { roleField } = props;
    if(roleField?.id){
      const service = props.serviceStore?.services.find(x => x.roles.some(y => y.name === roleField?.name));
      setSelectedServices(new ServicesModel({ ...service, roles: [ new RolesModel(roleField) ] }));
      setSelectedRole(new RolesModel(roleField));
      const site = new SiteModel({ number: roleField?.attributes.find(x => x.type === 'Site')?.value });
      setSelectedCustomer(new CustomerModel({
        name: roleField?.attributes.find(x => x.type === 'Customer')?.value,
        sites: [ site ],
      }));
      setSelectedSite(site);
      useUpsert.setFormValues(roleField);
      useUpsert.getField('appService').set(selectedServices);
      useUpsert.getField('roles').set(selectedRole);
      useUpsert.getField('sites').set(selectedSite);
    }
  }, []);

  const loadAppServices = (): ISelectOption[] => {
    const env = new EnvironmentVarsStore();
    const { serviceStore, user } = props;
    const isInternal: boolean = user?.provider === env.getVar(ENVIRONMENT_VARS.UWA_AD_PROVIDER);
    const uvXAppServiceId: string = env.getVar(ENVIRONMENT_VARS.UVX_APP_SERVICE_ID);
    return serviceStore?.services.filter(x => x.enabled && (isInternal || x.id !== uvXAppServiceId));
  }

  const loadCustomers = (): ISelectOption[] => {
    const { customerStore } = props;
    return customerStore?.customer.filter(x => x.status === 'ACTIVE');
  }

  const groupInputControls = (): IGroupInputControls => {
    return {
      title: 'RoleField',
      inputControls: [
        {
          fieldKey: 'appService',
          type: EDITOR_TYPES.DROPDOWN,
          options: loadAppServices(),
        },
        {
          fieldKey: 'roles',
          type: EDITOR_TYPES.DROPDOWN,
          isExists: isExists(),
        },
        {
          fieldKey: 'customer',
          type: EDITOR_TYPES.DROPDOWN,
          options: loadCustomers(),
        },
        {
          fieldKey: 'sites',
          type: EDITOR_TYPES.DROPDOWN,
        },
      ],
    };
  }

  const isExists = (): boolean => { 
    const { roleField, rolesField } = props;
    const name = selectedRole?.name || '';
    const roleExist = rolesField?.some(
      t => Utilities.isEqual(t.name, name) && !Utilities.isEqual(t.name, roleField?.name)
    );
    if(selectedSite?.siteUseId){
      const isSiteExist = rolesField?.some(t => Utilities.isEqual(
        t.attributes.find(x=> x.type === 'SiteId' && 
        (!roleField?.id || t.id != roleField?.id))?.value, selectedSite.siteUseId.toString()));
      const isCustomerExist = rolesField?.some(t => Utilities.isEqual(t.attributes.find(x=> x.type === 'CustomerId'
      && 
      (!roleField?.id || t.id != roleField?.id)
      )?.value, selectedCustomer?.customerId.toString()
      ));
      return isCustomerExist && isSiteExist && roleExist;
    }
    return roleExist;
  }

  const isUVGOApplication = (): boolean => {
    const env = new EnvironmentVarsStore();
    return selectedServices?.applicationId === env.getVar(ENVIRONMENT_VARS.UVGO_APPLICATION_ID);
  }

  const isUVXService = (): boolean => {
    const env = new EnvironmentVarsStore();
    return selectedServices?.id === env.getVar(ENVIRONMENT_VARS.UVX_APP_SERVICE_ID);
  }

  const isRoleSelectionValid = (): boolean => {
    if (!isUVXService() && isUVGOApplication() && !selectedCustomer?.id) {
      return true;
    }
    return false;
  }

  const setServiceValue = (_selectedService: ServicesModel): void => {
    resetRolesFields();
    if (!_selectedService) {
      setServiceUsers([]);
      setSelectedServices(null);
      useUpsert.getField('appService').set(null);
      return;
    }
    setSelectedServices(_selectedService);
    useUpsert.getField('appService').set(_selectedService);
  }

  const setRolesValue = (_selectedRole: RolesModel): void => {
    if (!_selectedRole) {
      resetRolesFields();
      return;
    }
    setSelectedRole(_selectedRole);
    useUpsert.getField('roles').set(_selectedRole);
  }

  const setSiteValue = (_selectedSite: SiteModel): void => {
    if (!_selectedSite) {
      resetSitesFields();
      return;
    }
    setSelectedSite(_selectedSite);
  }

  const resetRolesFields = () => {
    setSelectedRole(null);
    useUpsert.getField('roles').set(null);
  }

  const resetSitesFields = () => {
    setSelectedSite(null);
    useUpsert.getField('sites').set(null);
  }

  const setCustomerValue = (_selectedCustomer: CustomerModel): void => {
    resetSitesFields()
    if (!_selectedCustomer) {
      setCustomer([]);
      setSelectedCustomer(null);
      useUpsert.getField('customer').set(null);
      return;
    }
    setSelectedCustomer(_selectedCustomer);
    useUpsert.getField('customer').set(_selectedCustomer);
  }

  const searchCustomerUsers = (value: string, pageRequest?: IAPIGridRequest): void => {
    if (value.length <= 2) {
      return;
    }
    const { customerStore } = props;
    const request: IAPIGridRequest = {
      ...pageRequest,
      searchCollection: value,
    };
    UIStore.setPageLoader(true);
    customerStore?.getCustomers(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        debounceTime(500),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(users => {
        setCustomer(users.results);
      });
  }

  const onValueChange = (value: IOptionValue | IOptionValue[], fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
  };

  const dialogContent = (): ReactNode => {
    const { upsertRoleField } = props;
    return (
      <div>
        <div className={classes.formatContainer}>
          {groupInputControls().inputControls
            .filter(inputControl => !inputControl.isHidden)
            .map((inputControl: IViewInputControl, index: number) => {
              if (Utilities.isEqual(inputControl.fieldKey, 'appService') && true) {
                return (
                  <>
                    <div className={classes.searchContainer}>
                      <Typography className={classes.titleHeading}>App Service</Typography>
                      <AutoCompleteControl
                        placeHolder="Search App Services"
                        options={loadAppServices()}
                        value={selectedServices || { label: '', value: '' }}
                        onDropDownChange={selectedOption => setServiceValue(selectedOption as ServicesModel)}
                      />
                    </div>
                  </>
                );
              }
              if (Utilities.isEqual(inputControl.fieldKey, 'roles') && true) {
                return (
                  <>
                    <div className={classes.searchContainerRole}>
                      <AutoCompleteControl
                        isExists={isExists()}
                        field={useUpsert.getField(inputControl.fieldKey)}
                        placeHolder="Search Roles"
                        options={
                          props.serviceStore?.services.find(x => x.name === selectedServices?.name)
                            ?.roles || []
                        }
                        value={selectedRole || { label: '', value: '' }}
                        onDropDownChange={selectedOption =>
                          setRolesValue(selectedOption as RolesModel)
                        }
                      />
                    </div>
                  </>
                );
              }
              if (Utilities.isEqual(inputControl.fieldKey, 'customer') && true) {
                return (
                  <>
                    <div className={classes.label}>Optional Associations</div>
                    {localStates.showInlineError && isRoleSelectionValid() && (
                      <div className={classes.filledError}>uvGO Services require a customer and site association.</div>
                    )}
                    <div className={classes.searchContainer}>
                      <Typography className={classes.titleHeading}>Customer</Typography>
                      <AutoCompleteControl
                        popperWidth='fit-content'
                        placeHolder="Search Customer"
                        options={customer}
                        value={selectedCustomer}
                        filterOption={options =>
                          options.map(option => {
                            return {
                              ...option,
                              label: (option as CustomerModel).name,
                            };
                          })
                        }
                        onDropDownChange={selectedOption => setCustomerValue(selectedOption as CustomerModel)}
                        onSearch={(searchValue: string) => searchCustomerUsers(searchValue)}
                      />
                    </div>
                  </>
                );
              }
              if (Utilities.isEqual(inputControl.fieldKey, 'sites') && true) {
                return (
                  <>
                    <div className={classes.searchContainerRole}>
                      <AutoCompleteControl
                        field={useUpsert.getField(inputControl.fieldKey)}
                        placeHolder="Search Sites"
                        options={customer.find(x => x.name === selectedCustomer?.name)?.sites || []}
                        value={selectedSite || { label: '', value: '' }}
                        onDropDownChange={selectedOption => 
                          setSiteValue(selectedOption as SiteModel)
                        }
                      />
                    </div>
                  </>
                );
              }
              return (
                <>
                  <ViewInputControl
                    {...inputControl}
                    key={index}
                    isExists={inputControl.isExists}
                    classes={{
                      flexRow: classNames({
                        [classes.inputControl]: true,
                      }),
                    }}
                    field={useUpsert.getField(inputControl.fieldKey)}
                    isEditable={true}
                    onValueChange={(option, fieldKey) => onValueChange(option, inputControl.fieldKey)}
                  />
                </>
              );
            })}
          <div className={classes.btnContainer}>
            <div className={classes.btnContainerCancel}>
              <Button color="primary" variant="contained" size="small" onClick={() => ModalStore.close()}>
                Cancel
              </Button>
            </div>
            <div className={classes.btnContainerSave}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                disabled={useUpsert.form.hasError || isExists()}
                onClick={() => {
                  if(isRoleSelectionValid()){
                    localStates.showInlineError = true;
                    return;
                  }
                  localStates.showInlineError = false;
                  const { fieldType } = useUpsert.form.values();
                  if(selectedCustomer?.label){
                    delete (selectedCustomer as any).label
                  }
                  const model = new UserProfileRolesModel({
                    ...selectedRole,
                    id: useUpsert.viewMode === VIEW_MODE.NEW ? 0 : props.roleField?.id,
                    customer: new CustomerModel(selectedCustomer),
                    site: selectedSite
                  });
                  upsertRoleField(model);
                }}
              >
                {useUpsert.viewMode === VIEW_MODE.NEW ? 'Save' : 'Update'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Dialog
      title={props.title}
      open={true}
      classes={{
        dialogWrapper: classes.modalRoot,
        paperSize: classes.dialogWidth,
        header: classes.headerWrapper,
      }}
      onClose={() => ModalStore.close()}
      dialogContent={() => dialogContent()}
    />
  );
}
export default inject('serviceStore', 'customerStore')(observer(UserProfileRoleField));
